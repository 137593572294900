<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{$t('toolbar.companySetting')}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card outlined>
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.icon"
              @change="isAccess(tab)"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ $t(tab.title) }}</span>
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-profile />
            </v-tab-item>
            <v-tab-item>
              <account-settings-security />
            </v-tab-item>
            <v-tab-item>
              <db-query />
            </v-tab-item>
            <v-tab-item>
              <import-db-table />
            </v-tab-item>
            <v-tab-item>
              <user-list />
            </v-tab-item>
            <v-tab-item>
              <personal-menu-list />
            </v-tab-item>
            <v-tab-item>
              <document-series />
            </v-tab-item>
            <v-tab-item>
              <pdf-template />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <AccessDbInfo :dialog.sync="dialog" :tab.sync="tab" />
    </v-row>
  </v-container>
</template>

<script>
import AccountSettingsSecurity from './SettingsTab/AccountSettingsSecurity.vue'
import AccountProfile from './SettingsTab/AccountProfile.vue'
import DbQuery from './SettingsTab/DbQuery.vue'
import ImportDbTable from './SettingsTab/ImportDbTable.vue'
import UserList from './SettingsTab/UserList.vue'
import AccessDbInfo from './SettingsTab/Dialog/AccessDbInfo.vue'
import PersonalMenuList from './SettingsTab/PersonalMenuList.vue'
import DocumentSeries from './SettingsTab/DocumentSeries.vue'
import PdfTemplate from './SettingsTab/PdfTemplate.vue'

export default {
  name: 'Settings',
  components: {
    AccountSettingsSecurity,
    AccountProfile,
    DbQuery,
    AccessDbInfo,
    ImportDbTable,
    UserList,
    PersonalMenuList,
    DocumentSeries,
    PdfTemplate
  },
  data() {
    return {
      tab: null,
      dialog: false,
      tabs: [
        {title: 'settings.profile', icon: "mdi-account-outline"},
        {title: 'settings.security', icon: "mdi-lock-open-outline"},
        {title:'dbQuery.dbInfo',icon:"mdi-database"},
        {title:'dbQuery.importDbInfo',icon:"mdi-database-import"},
        {title: 'dbQuery.userList', icon: "mdi-account-group"},
        {title: 'dbQuery.personalMenu', icon: "mdi-filter-menu"},
        {title: 'documentSeries.documentSeries', icon: "mdi-counter"},
        {title: 'common.pdfTemplate', icon: "mdi-file-pdf-box"}
      ]
    }
  },
  watch: {
    tab(val) {
      if (val == 2) {
        this.$store.dispatch("dbQuery/GetTableList");
      } else if (val == 3) {
        this.$store.dispatch("dbQuery/GetTableList", 'tableName=employees, customers, suppliers, items, banks');
      }
    }
  },
  methods: {
    isAccess(tab) {
      if (tab.title === 'dbQuery.dbInfo' || tab.title === 'dbQuery.importDbInfo') {
        if (!this.$store.getters['accessDbInfo/isAuthenticated']) {
          this.dialog = true;
        } else {
          this.dialog = false;
        }
      } else {
        this.dialog = false;
      }
    }
  }
}
</script>
