<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('dbQuery.userList') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else-if="userList">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="userList"
              style="word-break: break-word"
              item-class="py-4"
              hide-default-footer
              :search="search"
              @pagination="newPagination"
            >
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
            </v-data-table>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2 text-subtitle-2">{{ $t('common.numberOfTotalRows') }} {{numberOfRows}}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton'

export default {
  name: 'UserList',
  components: {
    ListSkeleton,
  },
  data() {
    return {
      listSkeleton: false,
      search: this.$route.query.search || "",
      numberOfRows: ""
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.dispatch("GetUserList");
    this.listSkeleton = false;
  },
  watch: {
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)       
          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["userList"]),
    headers() {
      return [
        { text: this.$t('dbQuery.field.id'), value: "id", width: "80px", align: "start", sortable: true },
        { text: this.$t('dbQuery.field.name'), value: "name", width: "180px", align: "start", sortable: true },
        { text: this.$t('dbQuery.field.email'), value: "email", width: "350px", align: "start", sortable: false },
        { text: this.$t('dbQuery.field.type'), value: "type", width: "130px", align: "start", sortable: false },
        { text: this.$t('dbQuery.field.slug'), value: "role.slug", width: "150px", align: "start", sortable: false },
      ]
    }
  },
  methods: {
    newPagination(val) {
      this.numberOfRows = val.itemsLength
    },
  }
}
</script>
