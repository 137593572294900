<template>
  <v-container class="container--fluid grid-list-md pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-row
            no-gutters
            v-if="
              Object.keys(profileById).length > 0 &&
              !!model.is_locked &&
              model.user_id !== user.id
            "
          >
            <v-col cols="12" class="my-3">
              <span
                class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
                >*{{
                  $t("common.documentReadOnlyModeNotice", {
                    userId: model.user.name,
                  })
                }}</span
              >
            </v-col>
          </v-row>
          <v-card-text class="text-h5 pa-0">
            <FormSkeleton v-if="Object.keys(profileById).length <= 0" />
            <v-form v-else ref="form" v-model="valid" lazy-validation>
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 1"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="1"
                      :rules="[
                        (value) =>
                          !model.phone ||
                          (/^[0-9]*$/.test(model.phone) &&
                            model.phone.length >= 7 &&
                            model.phone.length <= 17),
                      ]"
                    >
                      <v-row :dense="true">
                        <v-col cols="6" :dense="true">
                          <h6 class="primary--text mt-2 font-weight-regular">
                            {{ $t("company.basicDetails") }}
                          </h6>
                        </v-col>
                        <v-col cols="6" class="text-end" :dense="true">
                          <v-btn
                            color="primary"
                            :loading="loading"
                            small
                            :disabled="
                              !valid ||
                              !accessRight.includes('edit') ||
                              (!!model.is_locked && model.user_id !== user.id)
                            "
                            @click="saveRole"
                          >
                            {{ $t("company.save") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-step>

                    <v-stepper-content
                      step="1"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            :disabled="true"
                            v-model.trim="model.name"
                            :label="$t('company.companyName')"
                            :rules="companyValid"
                            required
                          />
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            :disabled="true"
                            v-model="model.email"
                            :label="$t('company.field.email')"
                            :rules="emailValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.phone"
                            :label="$t('company.field.phones')"
                            :rules="phoneValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.name_he"
                            :label="$t('company.companyHeName')"
                            :rules="companyHeValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.address_line_1"
                            :label="$t('company.field.addressLine1')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.addressLine1'),
                                $t('common.less'),
                                255,
                                model.address_line_1
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.address_line_2"
                            :label="$t('company.field.addressLine2')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.addressLine2'),
                                $t('common.less'),
                                255,
                                model.address_line_2
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.address_line_3"
                            :label="$t('company.field.addressLine3')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.addressLine3'),
                                $t('common.less'),
                                255,
                                model.address_line_3
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 2"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="2"
                      :rules="[
                        (value) => !!model.authorized_dealer_no,
                        (value) => !/^\s*$/.test(model.authorized_dealer_no),
                        (value) =>
                          !model.vat_reg_no ||
                          /^[0-9]*$/.test(model.vat_reg_no),
                        (value) =>
                          lessThanValidation(
                            $t('company.field.vatRegNo'),
                            $t('common.less'),
                            9,
                            model.vat_reg_no
                          ),
                      ]"
                    >
                      <!-- , value => !model.calendar_email || /.+@.+\..+/.test(model.calendar_email) -->
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("company.otherDetails") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="2"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model.trim="model.authorized_dealer_no"
                            :label="$t('company.field.authorizedDealerNo')"
                            :rules="authorizedNoValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.vat_reg_no"
                            :label="$t('company.field.vatRegNo')"
                            :rules="vatRegNoValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.occasional_customer_no"
                            :label="$t('company.field.occasionalCustomerNo')"
                            :rules="occasionalCustomerNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.active_manager"
                            :label="$t('company.field.activeManager')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.activeManager'),
                                $t('common.less'),
                                35,
                                model.active_manager
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            :label="$t('company.field.vatPrecentage')"
                            @input="
                              model.vat_percentage =
                                $event !== '' ? $event : null
                            "
                            :rules="vatPrecentageValid"
                            v-model="model.vat_percentage"
                          >
                            <template v-slot:append>
                              <v-icon small color="primary"
                                >mdi-percent-outline</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-checkbox
                            class="my-2"
                            dense
                            v-model="model.warehouse_inventory_management"
                            :label="$t('company.field.warehouseInveManage')"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 1"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 3"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="3"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("company.displayText") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="3"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row class="custom-label-color">
                        <v-col cols="12" sm="3">
                          <v-textarea
                            class="pb-3"
                            rows="4"
                            v-model="model.beginning_proposal_text"
                            :label="
                              $t('company.field.textAttheBeginningOfaProposal')
                            "
                            :rules="[
                              lessThanValidation(
                                $t(
                                  'company.field.textAttheBeginningOfaProposal'
                                ),
                                $t('common.less'),
                                255,
                                model.beginning_proposal_text
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.end_proposal_text"
                            :label="$t('company.field.textAttheEndOfProposal')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textAttheEndOfProposal'),
                                $t('common.less'),
                                255,
                                model.end_proposal_text
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.beginning_order_text"
                            :label="
                              $t('company.field.textAttheBeginningoforder')
                            "
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textAttheBeginningoforder'),
                                $t('common.less'),
                                255,
                                model.beginning_order_text
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            v-model="model.end_order_text"
                            :label="$t('company.field.textAttheEndoforder')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textAttheEndoforder'),
                                $t('common.less'),
                                255,
                                model.end_order_text
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.beginning_receipt_text"
                            :label="$t('company.field.beginningReceiptText')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.beginningReceiptText'),
                                $t('common.less'),
                                255,
                                model.beginning_receipt_text
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.end_receipt_text"
                            :label="$t('company.field.endReceiptText')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.endReceiptText'),
                                $t('common.less'),
                                255,
                                model.end_receipt_text
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            v-model="model.beginning_delivery_note"
                            :label="
                              $t(
                                'company.field.textAtTheBeginningOfTheDelivery'
                              )
                            "
                            :rules="[
                              lessThanValidation(
                                $t(
                                  'company.field.textAtTheBeginningOfTheDelivery'
                                ),
                                $t('common.less'),
                                255,
                                model.beginning_delivery_note
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.end_delivery_note"
                            :label="
                              $t('company.field.textAtTheEndOfADeliveryNote')
                            "
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textAtTheEndOfADeliveryNote'),
                                $t('common.less'),
                                255,
                                model.end_delivery_note
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.beginning_invoice_text"
                            :label="
                              $t('company.field.textAtTheBeginningOfAnInvoice')
                            "
                            :rules="[
                              lessThanValidation(
                                $t(
                                  'company.field.textAtTheBeginningOfAnInvoice'
                                ),
                                $t('common.less'),
                                255,
                                model.beginning_invoice_text
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            v-model="model.end_invoice_text"
                            :label="$t('company.field.textAtTheEndOfAnInvoice')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textAtTheEndOfAnInvoice'),
                                $t('common.less'),
                                255,
                                model.end_invoice_text
                              ),
                            ]"
                            required
                          />
                          <v-textarea
                            rows="4"
                            class="pb-3"
                            dense
                            v-model="model.due_date_text"
                            :label="$t('company.field.textForDueDate')"
                            :rules="[
                              lessThanValidation(
                                $t('company.field.textForDueDate'),
                                $t('common.less'),
                                40,
                                model.due_date_text
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 4"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 4"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="4"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("company.printingAndPricing") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="4"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            v-model="model.print_due_date"
                            :label="$t('company.field.printingDueDate')"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            v-model="model.total_rounding"
                            :label="$t('company.field.totalRounding')"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            v-model="model.print_balance_invoice"
                            :label="
                              $t('company.field.balancePrintingOnInvoice')
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model.trim="model.decimal_points"
                            :label="$t('company.field.decimalPoint')"
                            :rules="decimalNoValid"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            @click="e6 = 5"
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 5"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="5"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("company.conditionsAndRestrictions") }}
                      </h6>
                    </v-stepper-step>
                    <v-stepper-content
                      step="5"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="4">
                          <v-checkbox
                            :dense="true"
                            v-model="model.title"
                            :label="$t('company.field.aTitleIsRequired')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            dense
                            persistent-hint
                            v-model="model.date_format"
                            :label="$t('company.field.dateFormatHint')"
                            :hint="$t('company.field.dateFormatLabel')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            dense
                            v-model="model.customer_bookkeeping_number"
                            :label="
                              $t('company.field.bookkeepingNumberIsRequired')
                            "
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            dense
                            v-model="model.warehouse_complusory"
                            :label="$t('company.field.warehouseCompulsory')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-radio-group v-model="model.calander_type" row>
                            <v-radio
                              :label="$t('calendar.google')"
                              value="google"
                            ></v-radio>
                            <v-radio
                              :label="$t('calendar.outlook')"
                              value="outlook"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                          <h6 class="primary--text font-weight-regular">
                            {{ $t("company.field.documentIncludingVat") }}
                          </h6>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            dense
                            v-model="model.quotation"
                            :label="$t('route.quotationIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.invoice"
                            :label="$t('route.invoiceIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.purchase_order"
                            :label="$t('route.purchaseOrder')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            dense
                            v-model="model.order"
                            :label="$t('route.orderIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.credit_invoice"
                            :label="$t('route.creditInvoiceIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.supplier_delivery_notes"
                            :label="$t('route.supplierDeliveryNote')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            dense
                            v-model="model.proforma_invoice"
                            :label="$t('route.proformaIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.receipt_tax_invoice"
                            :label="$t('route.receiptTaxInvoiceIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.supplier_invoice"
                            :label="$t('route.supplierInvoice')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            dense
                            v-model="model.delivery"
                            :label="$t('route.deliveryIncludingVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.return_certificate"
                            :label="$t('route.returnCertificateVat')"
                          />
                          <v-checkbox
                            dense
                            v-model="model.supplier_credit_invoice"
                            :label="$t('route.supplierCreditInvoice')"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 4"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            @click="e6 = 6"
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 6"
                      editable
                      @click.native="accordionStepper(e6)"
                      :rules="[
                        (value) =>
                          !topLogoImage || topLogoImage.size <= 2000000,
                        (value) =>
                          !bottomLogoImage || bottomLogoImage.size <= 2000000,
                      ]"
                      step="6"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("company.companyLogo") }}
                      </h6>
                    </v-stepper-step>
                    <v-stepper-content
                      step="6"
                      class="ms-7 mb-auto pb-0 me-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-file-input
                            hide-details="auto"
                            @change="previewTopLogo"
                            v-model="topLogoImage"
                            :rules="
                              filesValid.concat(
                                uploadImageValidation(topLogoImage, 'top logo')
                              )
                            "
                            accept="image/*"
                            :label="$t('company.field.topLogo')"
                          >
                            <template v-slot:append-outer>
                              <div>
                                <v-btn
                                  v-if="topLogoUrl"
                                  icon
                                  @click="topLogoViewDialog = true"
                                >
                                  <v-icon color="primary"
                                    >mdi-eye-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                              <div>
                                <v-btn
                                  v-if="model.top_logo_media_id"
                                  icon
                                  @click="
                                    deleteTopLogoImage(model.top_logo_media_id)
                                  "
                                >
                                  <v-icon color="error"
                                    >mdi-delete-sweep-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-file-input
                            hide-details="auto"
                            @change="previewBottomLogo"
                            v-model="bottomLogoImage"
                            :rules="
                              filesValid.concat(
                                uploadImageValidation(
                                  bottomLogoImage,
                                  'bottom logo'
                                )
                              )
                            "
                            accept="image/*"
                            :label="$t('company.field.bottomLogo')"
                          >
                            <template v-slot:append-outer>
                              <div>
                                <v-btn
                                  v-if="bottomLogoUrl"
                                  icon
                                  @click="bottomLogoViewDialog = true"
                                >
                                  <v-icon color="primary"
                                    >mdi-eye-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                              <div>
                                <v-btn
                                  v-if="model.bottom_logo_media_id"
                                  icon
                                  @click="
                                    deleteBottomLogoImage(
                                      model.bottom_logo_media_id
                                    )
                                  "
                                >
                                  <v-icon color="error"
                                    >mdi-delete-sweep-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 mt-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 5"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ImageViewDialog
        v-if="topLogoUrl"
        :profileUrl.sync="topLogoUrl"
        :dialog.sync="topLogoViewDialog"
        :width="'226'"
        :height="'128'"
      />
      <ImageViewDialog
        v-if="bottomLogoUrl"
        :profileUrl.sync="bottomLogoUrl"
        :dialog.sync="bottomLogoViewDialog"
        :width="'614'"
        :height="'50'"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ImageViewDialog from "./Dialog/ImageViewDialog.vue";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import { changedFormData } from "@/utils";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "CompanyAction",
  components: {
    ImageViewDialog,
    FormSkeleton,
    Alert,
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      loading: false,
      valid: false,
      model: {
        calander_type: "google",
        decimal_points: 2,
      },
      originModel: {},
      e6: 1,
      previousStep: 1,
      topLogoViewDialog: false,
      topLogoImage: null,
      topLogoUrl: null,
      deleteTopLogoId: null,
      bottomLogoViewDialog: false,
      bottomLogoImage: null,
      bottomLogoUrl: null,
      deleteBottomLogoId: null,
      topImageWidth: null,
      topImageHeight: null,
      bottomImageWidth: null,
      bottomImageHeight: null,
    };
  },
  computed: {
    companyValid() {
      return [
        (v) => !!v || this.$t("formRules.companyRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.companyRequired"),
        (v) => (v && v.length >= 5) || this.$t("formRules.companyValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("company.companyName"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    companyHeValid() {
      return [
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.companyRequired"),
        (v) =>
          !v ||
          (v && v.length >= 5) ||
          this.$t("formRules.companyValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("company.companyHeName"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    occasionalCustomerNoValid() {
      return [
        (v) =>
          !v ||
          /^[0-9]*$/.test(v) ||
          this.$t("formRules.occasionalCustomerNoInteger"),
        (v) =>
          !v ||
          (v && v.length <= 4) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("company.field.occasionalCustomerNo"),
            type: this.$t("common.less"),
            size: 4,
          }),
      ];
    },
    vatRegNoValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.vatRegNoInteger"),
        (v) =>
          !v ||
          (v && v.length <= 9) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("company.field.vatRegNo"),
            type: this.$t("common.less"),
            size: 9,
          }),
      ];
    },
    authorizedNoValid() {
      return [
        (v) => !!v || this.$t("formRules.authorizedNoRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.authorizedNoRequired"),
        (v) =>
          !v ||
          (v && v.length <= 35) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("company.field.authorizedDealerNo"),
            type: this.$t("common.less"),
            size: 35,
          }),
      ];
    },
    decimalNoValid() {
      return [
        (v) => !!v || this.$t("formRules.decimalPoint"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.decimalNoRequired"),
        (v) =>
          !v ||
          /^(?!.*(.)\1)[2-4]{1}$|^(?!.*(.)\1)[0-9]{2,4}$/.test(v) ||
          this.$t("formRules.decimalValidationMessage", {
            fieldName: this.$t("company.field.decimalPoints"),
            type: this.$t("common.exact"),
            size: "2, 3, or 4",
          }),
      ];
    },
    emailValid() {
      return [
        (v) => !!v || this.$t("formRules.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("formRules.emailValidMessage"),
      ];
    },
    // calendarEmailValid() {
    //   return [
    //     v => /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage')
    //   ];
    // },
    phoneValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.phoneNoInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.phoneNoValidMessage"),
      ];
    },
    filesValid() {
      return [(v) => !v || v.size <= 2000000 || this.$t("formRules.imageSize")];
    },
    ...mapGetters({
      profileById: "profile/profileById",
      accessRight: "accessRight",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
    }),
  },
  async mounted() {
    await this.$store.dispatch("profile/GetCompanyById");
  },
  watch: {
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    profileById() {
      if (Object.keys(this.profileById).length > 0) {
        this.model = Object.assign({}, this.model, this.profileById);
        if (this.model.topLogo) {
          this.topLogoUrl =
            this.model.topLogo.file_path +
            "?" +
            new Date().toLocaleString("sv-SE");
        }
        if (this.model.bottomLogo) {
          this.bottomLogoUrl =
            this.model.bottomLogo.file_path +
            "?" +
            new Date().toLocaleString("sv-SE");
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Company",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
    await this.$store.dispatch("profile/GetCompanyById");
    if (!this.model.is_locked && this.model.id) {
      await this.$store.dispatch("PostDocumentOpenAction", {
        table: "Company",
        id: this.model.id,
        is_locked: true,
      });
    }
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "Company",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    (this.model = {}), this.$store.commit("profile/SET_PROFILE_BY_ID", {});
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Company",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 6) {
        this.e6 = 6;
        this.previousStep = 6;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    uploadImageValidation(image, type) {
      let self = this;
      if (type === "top logo" && image) {
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            self.topImageWidth = img.width;
            self.topImageHeight = img.height;
          };
          img.src = evt.target.result;
        };
        if (this.topImageWidth <= 226 && this.topImageHeight <= 128) {
          return true;
        } else {
          return this.$t("formRules.imageDimenstionRequired", {
            dimenstion: "226 × 128",
          });
        }
      } else if (type === "bottom logo" && image) {
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            self.bottomImageWidth = img.width;
            self.bottomImageHeight = img.height;
          };
          img.src = evt.target.result;
        };
        if (this.bottomImageWidth <= 614 && this.bottomImageHeight <= 50) {
          return true;
        } else {
          return this.$t("formRules.imageDimenstionRequired", {
            dimenstion: "614 × 50",
          });
        }
      } else {
        return true;
      }
    },
    previewTopLogo() {
      if (this.topLogoImage) {
        this.topLogoUrl = URL.createObjectURL(this.topLogoImage);
      } else {
        if (this.model.topLogo && !this.deleteTopLogoId) {
          this.topLogoUrl =
            this.model.topLogo.file_path +
            "?" +
            new Date().toLocaleString("sv-SE");
        } else {
          this.topLogoUrl = "";
        }
      }
    },
    deleteTopLogoImage(id) {
      this.deleteTopLogoId = id;
      this.model.top_logo_media_id = null;
      this.topLogoUrl = null;
    },
    previewBottomLogo() {
      if (this.bottomLogoImage) {
        this.bottomLogoUrl = URL.createObjectURL(this.bottomLogoImage);
      } else {
        if (this.model.bottomLogo && !this.deleteBottomLogoId) {
          this.bottomLogoUrl =
            this.model.bottomLogo.file_path +
            "?" +
            new Date().toLocaleString("sv-SE");
        } else {
          this.bottomLogoUrl = "";
        }
      }
    },
    deleteBottomLogoImage(id) {
      this.deleteBottomLogoId = id;
      this.model.bottom_logo_media_id = null;
      this.bottomLogoUrl = null;
    },
    async saveRole() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        // top logo image upload
        if (this.topLogoImage) {
          let formData = new FormData();
          formData.append("image", this.topLogoImage);
          if (this.model.top_logo_media_id) {
            await this.$store.dispatch("profile/EditMedia", {
              media_id: this.model.top_logo_media_id,
              formData: formData,
            });
          } else {
            let imageData = await this.$store.dispatch(
              "profile/SetMedia",
              formData
            );
            if (imageData) {
              this.model.top_logo_media_id = imageData.data.id;
            }
          }
        }
        // bottom logo image upload
        if (this.bottomLogoImage) {
          let formData = new FormData();
          formData.append("image", this.bottomLogoImage);
          if (this.model.bottom_logo_media_id) {
            await this.$store.dispatch("profile/EditMedia", {
              media_id: this.model.bottom_logo_media_id,
              formData: formData,
            });
          } else {
            let imageData = await this.$store.dispatch(
              "profile/SetMedia",
              formData
            );
            if (imageData) {
              this.model.bottom_logo_media_id = imageData.data.id;
            }
          }
        }
        // save only update model value using changedFormData from utils
        let saveData = changedFormData(this.model, this.originModel);
        data = await this.$store
          .dispatch("profile/EditCompany", { company: saveData })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
        if (data) {
          if (this.deleteTopLogoId) {
            this.$store.dispatch(
              "profile/DeleteMediaById",
              this.deleteTopLogoId
            );
          }
          if (this.deleteBottomLogoId) {
            this.$store.dispatch(
              "profile/DeleteMediaById",
              this.deleteBottomLogoId
            );
          }
          this.loading = false;
          this.$store.commit("profile/SET_PROFILE_BY_ID", {});
          this.$refs.form.reset();
          (this.model = {}), (this.deleteTopLogoId = null);
          this.deleteBottomLogoId = null;
          await this.$store.dispatch("profile/GetCompanyById");
        } else {
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .custom-label-color .v-textarea textarea {
  line-height: 18px;
  font-size: 12px;
  margin-top: 3px;
  text-align: justify;
  padding: 0 3px 0 3px;
  resize: none;
}
::v-deep .custom-label-color .v-textarea textarea::-webkit-scrollbar {
  width: 3px;
}
::v-deep .custom-label-color .v-textarea .v-label--active {
  font-weight: bold;
  opacity: 1;
  font-size: 14px !important;
  margin-bottom: 2px;
}
::v-deep .custom-label-color .v-textarea .v-label {
  font-size: 12px;
}
::v-deep .v-input--checkbox .v-messages {
  padding-left: 32px;
  color: var(--v-primary-base);
}
</style>
