<template>
  <v-container class="container--fluid grid-list-md">
    <v-form ref="form" v-model="valid" lazy-validation>
      <Alert :alert.sync="alert" :message="message" />
      <v-row>
        <v-col cols="4">
          <v-card class="mx-auto" flat>
            <v-card-title class="py-1 primary--text">
              <v-icon color="primary">mdi-file-export-outline</v-icon>
              <h6 class="ms-2 font-weight-regular">
                {{$t("dbQuery.selectTable")}}
              </h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row :dense="true">
                <v-col cols="12">
                  <v-autocomplete ref="table" name="table" v-model="selectedTable" @change="headerKey" :items="tableList" dense flat single-line :rules="tableValid" required clearable>
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs">
                        {{item}}
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" class="text-center">
                  <v-btn class="me-1" small color="primary" outlined @click="downloadExcelFile" :disabled="!!$refs.table && !$refs.table.valid">
                    <v-icon left>mdi-microsoft-excel</v-icon>
                    {{$t("dbQuery.download")}}
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto" flat>
            <v-card-title class="py-1 primary--text">
              <v-icon color="primary">mdi-file-import-outline</v-icon>
              <h6 class="ms-2 font-weight-regular">
                {{$t("dbQuery.importExcel")}}
              </h6>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row :dense="true">
                <v-col cols="12">
                  <v-file-input
                    class="asterisk"
                    ref="file"
                    name="file"
                    dense
                    v-model="excelFile"
                    :rules="fileValid"
                    required
                    accept=".csv"
                    :label="$t('dbQuery.selectFile')"
                  />
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn class="me-1 my-3" small color="primary" outlined @click="importFile" :disabled="!excelFile || !valid">
                    {{$t("dbQuery.showData")}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto" flat>
            <v-card-title class="py-1 primary--text">
              <v-icon color="primary">mdi-file-upload-outline</v-icon>
              <h6 class="ms-2 font-weight-regular">
                {{$t("dbQuery.uploadExcel")}}
              </h6>
            </v-card-title>
            <v-divider />
            <v-row :dense="true" v-if="showTable">
              <v-col>
                <v-card-text>
                  <v-select
                    :dense="true"
                    hide-details="auto"
                    v-model="selectedHeaders"
                    :items="headersData"
                    item-text="text"
                    item-value="id"
                    return-object
                    clearable
                    multiple
                    outlined
                    :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                    :label="$t('dbQuery.selectFields')"
                  >
                  </v-select>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row :dense="true" class="my-2 d-flex justify-space-around">
              <v-col cols="3" class="text-center" v-if="showTable">
                <v-btn class="me-1" small color="primary" outlined @click="updateCsvData" :disabled="!selectedHeaders.length > 0">
                  {{$t("dbQuery.save")}}
                </v-btn>
              </v-col>
              <v-col cols="3" class="text-center">
                <v-btn class="me-1" small color="primary" outlined @click="uploadFile" :disabled="!selectedTable || !valid">
                  <v-icon left>mdi-microsoft-excel</v-icon>
                  {{$t("dbQuery.upload")}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-divider />
    <v-row v-if="showTable">
      <v-col cols="12">
        <v-card class="mx-auto" flat>
          <v-card-title class="py-1 primary--text">
            <v-icon color="primary">mdi-database-edit</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{$t("dbQuery.fileData")}}
            </h6>
          </v-card-title>
          <v-card-text class="text-h5 mx-2">
            <v-data-table
              :dense="true"
              height="300px"
              class="custom-table mb-0 ma-5 row-pointer"
              item-class="py-4"
              :headers="headersData"
              fixed-header
              :items="dataTableItem"
              style="word-break: break-word"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr style="height: 70px" v-if="selectedTable">
                    <th 
                      v-for="(item,index) in headers"
                      :key="item.text+''+index"
                    >
                      <v-select
                        v-if="selectedHeaders.find(el => el.value == item.value)"
                        :dense="true"
                        hide-details="auto"
                        v-model="selectDBColumn[index]"
                        :items="newHeaderName"
                        clearable
                        outlined
                        :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                        :label="$t('dbQuery.selectDatabaseColumn')"
                      >
                      </v-select>
                    </th>
                  </tr>
                </thead>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import exportFromJSON from 'export-from-json';
import Alert from "@/components/Alert";
import papa from 'papaparse';

export default {
  name: "ImportDbTable",
  components: {
    Alert,
  },
  data() {
    return {
      selectedTable: "",
      valid: false,
      downloadBtnDisable: false,
      message: "",
      alert: false,
      excelFile: null,
      csvData: [],
      headersData: [],
      dataTableItem: [],
      showTable: false,
      jsonData: [],
      selectedHeaders: [],
      newHeaderName: [],
      selectDBColumn: {}
    };
  },
  mounted() {
    this.selectedHeaders = this.headersData
  },
  computed: {
    ...mapGetters({
      tableList: "dbQuery/tableList"
    }),
    tableValid() {
      return [(v) => !!v || this.$t("dbQuery.message.selectTable")];
    },
    chooseHeader() {
      // return this.selectedHeaders.map(function (el) {
      //   return el['value'];
      // })
      return Object.values(this.selectDBColumn)
    },
    chooseData() {
      return this.jsonData.map((obj) => Object.values(obj))
    },
    fileValid() {
      return [
        (v) => !!v || this.$t("dbQuery.message.fileIsRequired"),
        (v) => (v && v.size > 0) || this.$t("dbQuery.message.fileIsRequired"),
        (v) => (v && v.type == "text/csv") || this.$t("dbQuery.message.csvRequired"),
      ];
    },
  },
  methods: {
    async downloadExcelFile() {
      if (this.$refs.table && this.$refs.table.validate('table')) {
        let columList = await this.$store.dispatch("dbQuery/GetColumnByTable", {tableName: this.selectedTable});
        let data = [columList.reduce((a, v) => ({ ...a, [v]: ''}), {})]
        const fileName = this.selectedTable
        const exportType = exportFromJSON.types.csv
        exportFromJSON({data, fileName, exportType})
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
        this.downloadBtnDisable = true;
      }
    },
    headerKey() {
      if (this.selectedTable === 'banks') {
        this.$store.dispatch("bank/GetBank").then(async(data) => {
          this.newHeaderName = Object.keys(data[0])
        })
      }
      else if (this.selectedTable === 'customers') {
        this.$store.dispatch("customer/GetCustomer").then(async(data) => {
          this.newHeaderName = Object.keys(data[0])
        })
      }
      else if (this.selectedTable === 'employees') {
        this.$store.dispatch("employee/GetEmployee").then(async(data) => {
          this.newHeaderName = Object.keys(data[0])
        })
      }
      else if (this.selectedTable === 'items') {
        this.$store.dispatch("product/GetProduct").then(async(data) => {
          this.newHeaderName = Object.keys(data[0])
        })
      }
      else if (this.selectedTable === 'suppliers') {
        this.$store.dispatch("supplier/GetSupplier").then(async(data) => {
          this.newHeaderName = Object.keys(data[0])
        })
      }
    },
    updateCsvData() {
      // this.headersData.filter((s) => this.selectedHeaders.some((d) => d.value === s.value))
      this.jsonData = this.dataTableItem.map((o) => Object.fromEntries(this.selectedHeaders.map((k, index) => [this.selectDBColumn[Object.keys(this.selectDBColumn)[index]], o[k.value]])))
    },
    importFile() {
      let that = this;
      if(that.excelFile) {
        let csvFile = that.excelFile
        let headers = [];
        papa.parse(csvFile, {
          header: false,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete(result) {
            that.csvData = result.data;
            headers = result.data.shift();
            that.headersData = headers.map((el) => {
              return { value: el, text: el, width: "140px" }
            })
            let arr = [];
            let obj = {};
            that.csvData.forEach((el) => {
              el.forEach((element, index) => {
                obj[headers[index]] = element;
              });
              arr.push(obj);
              obj = Object.assign({});
            });
            that.dataTableItem = arr;
          }
        })
        this.showTable = true;
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','
              line += array[i][index];
          }
          str += line + '\r\n';
      }
      return str;
    },
    uploadFile() {
      if (this.chooseHeader) {
        this.chooseData.unshift(this.chooseHeader);
      }
      var jsonObject = JSON.stringify(this.chooseData);
      var csv = this.convertToCSV(jsonObject);
      var exportedFileName = "dbTableFile.csv";
      let formData = new FormData();
      var blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      })
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFileName);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFileName)
          link.style.visibility = 'hidden';
        }
      }
      var fileUpload = new File([blob], "dbTableFile.csv", {
        lastModified: new Date()
      })
      formData.append("tableName", this.selectedTable);
      formData.append("excel", fileUpload)
      this.$store.dispatch("dbQuery/UploadExcel", formData);
    }
  }
}
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>