<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-text class="text-h5 pa-2">
            <v-row>
              <v-col cols="3">
                <v-card class="mx-auto" flat>
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="12">
                        <v-select
                          dense
                          v-model="year"
                          :items="years"
                          :label="$t('documentSeries.year')"
                          outlined
                          @change="yearChange"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="9"></v-col>
              <v-col cols="6">
                <v-form
                  ref="form1"
                  v-model="valid1"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text d-flex">
                      <v-icon color="primary justify"
                        >mdi-numeric-1-circle</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.proformaInvoice") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveProformaInvoice"
                        :disabled="!valid1"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in proformaInvoice"
                                  :key="index"
                                  :class="
                                    index + 1 == proformaInvoice.length
                                      ? 'newRow'
                                      : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowProformaInvoice"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form2"
                  v-model="valid2"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-2-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.delivery") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveDelivery"
                        :disabled="!valid2"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in delivery"
                                  :key="index"
                                  :class="
                                    index + 1 == delivery.length ? 'newRow' : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowDelivery"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form3"
                  v-model="valid3"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-3-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.invoice") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveInvoice"
                        :disabled="!valid3"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in invoice"
                                  :key="index"
                                  :class="
                                    index + 1 == invoice.length ? 'newRow' : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowInvoice"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form4"
                  v-model="valid4"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-4-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.receiptTaxInvoice") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveReceiptTaxInvoice"
                        :disabled="!valid4"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in receiptTaxInvoice"
                                  :key="index"
                                  :class="
                                    index + 1 == receiptTaxInvoice.length
                                      ? 'newRow'
                                      : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowReceiptTaxInvoice"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form5"
                  v-model="valid5"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-5-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.creditInvoice") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveCreditInvoice"
                        :disabled="!valid5"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in creditInvoice"
                                  :key="index"
                                  :class="
                                    index + 1 == creditInvoice.length
                                      ? 'newRow'
                                      : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowCreditInvoice"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form6"
                  v-model="valid6"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-6-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.receipt") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveReceipt"
                        :disabled="!valid6"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in receipt"
                                  :key="index"
                                  :class="
                                    index + 1 == receipt.length ? 'newRow' : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowReceipt"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-form
                  ref="form7"
                  v-model="valid7"
                  lazy-validation
                  :disabled="accessRight.length < 2 && accessRight.includes('show')"
                >
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-numeric-7-circle</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("documentSeries.returnCertificate") }}
                      </h6>
                      <v-btn
                        color="primary"
                        :class="locale == 'he' ? 'mr-auto' : 'ml-auto'"
                        small
                        :loading="loading"
                        @click="saveReturnCertificate"
                        :disabled="!valid7"
                      >
                        {{ $t("quotation.save") }}
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("documentSeries.index") }}
                                  </th>
                                  <th :style="{ width: '60px' }">
                                    {{ $t("documentSeries.series") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("documentSeries.from") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("documentSeries.description") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("documentSeries.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in returnCertificate"
                                  :key="index"
                                  :class="
                                    index + 1 == returnCertificate.length ? 'newRow' : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.series"
                                      :rules="seriesValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.from_no"
                                      :rules="fromNoValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="item.description"
                                      :rules="descriptionValid"
                                      required
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="6" class="text-end">
                                        <v-btn
                                          icon
                                          color="primary"
                                          small
                                          @click="addRowReturnCertificate"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(item, index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay v-if="circiular" :value="circiular" class="overlay">
                            <v-icon size="56" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DocumentSeries",
  data() {
    return {
      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,
      valid5: false,
      valid6: false,
      valid7: false,
      loading: false,
      year: "",
      years: [],
      circiular: false,
      proformaInvoice: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "ProformaInvoice",
          year: null,
        },
      ],
      delivery: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "Delivery",
          year: null,
        },
      ],
      invoice: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "Invoice",
          year: null,
        },
      ],
      creditInvoice: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "CreditInvoice",
          year: null,
        },
      ],
      receiptTaxInvoice: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "ReceiptTaxInvoice",
          year: null,
        },
      ],
      receipt: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "Receipt",
          year: null,
        },
      ],
      returnCertificate: [
        {
          series: null,
          from_no: null,
          description: null,
          type: "ReturnCertificate",
          year: null,
        },
      ]
    };
  },
  created() {
    const startYear = 1990;
    const currentYear = new Date().getFullYear();
    for (let year = currentYear+1; year >= startYear; year--) {
      this.years.push(year);
    }
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      locale: "locale"
    }),
    fromNoValid() {
      return [
        v => !!v || this.$t('formRules.fromNoRequired'),
        v => !v || /^\d{0,8}(\.\d{1,4})?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 10) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('documentSeries.from'), type: this.$t('common.less'), size: 10 }),
      ];
    },
    seriesValid() {
      return [
        v => !!v || this.$t('formRules.seriesRequired'),
        v => !v || /^\d{0,8}(\.\d{1,4})?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 5) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('documentSeries.series'), type: this.$t('common.less'), size: 5 }),
      ];
    },
    descriptionValid() {
      return [
        v => !!v || this.$t('formRules.descriptionRequired'),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("documentSeries.description"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
  },
  mounted() {
    this.currentYear();
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ProformaInvoice,${this.year}`).then((res) => {
      if (res.length > 0) {this.proformaInvoice = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Delivery,${this.year}`).then((res) => {
      if (res.length > 0) {this.delivery = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${this.year}`).then((res) => {
      if (res.length > 0) {this.invoice = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReceiptTaxInvoice,${this.year}`).then((res) => {
      if (res.length > 0) {this.receiptTaxInvoice = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|CreditInvoice,${this.year}`).then((res) => {
      if (res.length > 0) {this.creditInvoice = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${this.year}`).then((res) => {
      if (res.length > 0) {this.receipt = JSON.parse(JSON.stringify(res))}
    })
    this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReturnCertificate,${this.year}`).then((res) => {
      if (res.length > 0) {this.returnCertificate = JSON.parse(JSON.stringify(res))}
    })
  },
  methods: {
    async yearChange(year) {
      this.circiular = true;
      await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ProformaInvoice,${year}`).then((res) => {
        this.circiular = true;
        this.proformaInvoice = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.proformaInvoice.length < 1) {
          this.addRowProformaInvoice();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Delivery,${year}`).then((res) => {
        this.circiular = true;
        this.delivery = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.delivery.length < 1) {
          this.addRowDelivery();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${year}`).then((res) => {
        this.circiular = true;
        this.invoice = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.invoice.length < 1) {
          this.addRowInvoice();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReceiptTaxInvoice,${year}`).then((res) => {
        this.circiular = true;
        this.receiptTaxInvoice = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.receiptTaxInvoice.length < 1) {
          this.addRowReceiptTaxInvoice();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|CreditInvoice,${year}`).then((res) => {
        this.circiular = true;
        this.creditInvoice = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.creditInvoice.length < 1) {
          this.addRowCreditInvoice();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${year}`).then((res) => {
        this.circiular = true;
        this.receipt = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.receipt.length < 1) {
          this.addRowReceipt();  
        }
      })
      this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReturnCertificate,${year}`).then((res) => {
        this.circiular = true;
        this.returnCertificate = JSON.parse(JSON.stringify(res))
        this.circiular = false;
        if (this.returnCertificate.length < 1) {
          this.addRowReturnCertificate();  
        }
      })
      this.circiular = false;
    },
    currentYear() {
      const currentDate = new Date();
      this.year = currentDate.getFullYear();
      if (this.proformaInvoice.length > 0) {
        this.proformaInvoice[0].year = this.year;
      }
      if (this.delivery.length > 0) {
        this.delivery[0].year = this.year;
      }
      if (this.invoice.length > 0) {
        this.invoice[0].year = this.year;
      }
      if (this.creditInvoice.length > 0) {
        this.creditInvoice[0].year = this.year;
      }
      if (this.receiptTaxInvoice.length > 0) {
        this.receiptTaxInvoice[0].year = this.year;
      }
      if (this.receipt.length > 0) {
        this.receipt[0].year = this.year;
      }
      if (this.returnCertificate.length > 0) {
        this.returnCertificate[0].year = this.year;
      }
    },
    addRowReceipt() {
      this.receipt.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "Receipt",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowCreditInvoice() {
      this.creditInvoice.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "CreditInvoice",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowReceiptTaxInvoice() {
      this.receiptTaxInvoice.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "ReceiptTaxInvoice",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowInvoice() {
      this.invoice.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "Invoice",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowDelivery() {
      this.delivery.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "Delivery",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowProformaInvoice() {
      this.proformaInvoice.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "ProformaInvoice",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    addRowReturnCertificate() {
      this.returnCertificate.push({
        from_no: null,
        description: null,
        year: this.year,
        type: "ReturnCertificate",
        series: null,
      });
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    async deleteRow(item,index) {
      if (item.id) {
        await this.$store.dispatch('documentSeries/DeleteSeriesDocument', item.id)
        if (item.type === 'Invoice') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${this.year}`).then((res) => {this.invoice = JSON.parse(JSON.stringify(res))})
          this.addRowInvoice();
        } else if (item.type === 'CreditInvoice') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|CreditInvoice,${this.year}`).then((res) => {this.creditInvoice = JSON.parse(JSON.stringify(res))})
          this.addRowCreditInvoice();
        } else if (item.type === 'ProformaInvoice') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ProformaInvoice,${this.year}`).then((res) => {this.proformaInvoice = JSON.parse(JSON.stringify(res))})
          this.addRowProformaInvoice();
        } else if (item.type === 'Delivery') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Delivery,${this.year}`).then((res) => {this.delivery = JSON.parse(JSON.stringify(res))})
          this.addRowDelivery();
        } else if (item.type === 'Receipt') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${this.year}`).then((res) => {this.receipt = JSON.parse(JSON.stringify(res))})
          this.addRowReceipt();
        } else if (item.type === 'ReturnCertificate') {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReturnCertificate,${this.year}`).then((res) => {this.returnCertificate = JSON.parse(JSON.stringify(res))})
          this.addRowReturnCertificate();
        } else {
          await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReceiptTaxInvoice,${this.year}`).then((res) => {this.receiptTaxInvoice = JSON.parse(JSON.stringify(res))})
          this.addRowReceiptTaxInvoice();
        }
      } else {
        if (item.type === "Invoice") {
          this.invoice.splice(index,1);
        } else if (item.type === 'CreditInvoice') {
          this.creditInvoice.splice(index,1);
        } else if (item.type === 'ProformaInvoice') {
          this.proformaInvoice.splice(index,1);
        } else if (item.type === 'Delivery') {
          this.delivery.splice(index,1);
        } else if (item.type === 'Receipt') {
          this.receipt.splice(index,1);
        } else if (item.type === 'ReturnCertificate') {
          this.returnCertificate.splice(index,1);
        } else {
          this.receiptTaxInvoice.splice(index,1);
        }
      }
    },
    async saveProformaInvoice() {
      if (this.$refs.form1.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.proformaInvoice)                   
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ProformaInvoice,${this.year}`).then((res) => {this.proformaInvoice = res})
      } else {
        this.valid1 = false;
      }
    },
    async saveDelivery() {
      if (this.$refs.form2.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.delivery)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Delivery,${this.year}`).then((res) => {this.delivery = res})
      } else {
        this.valid2 = false;
      }
    },
    async saveInvoice() {
      if (this.$refs.form3.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.invoice)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Invoice,${this.year}`).then((res) => {this.invoice = res})
      } else {
        this.valid3 = false;
      }
    },
    async saveReceiptTaxInvoice() {
      if (this.$refs.form4.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.receiptTaxInvoice)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReceiptTaxInvoice,${this.year}`).then((res) => {this.receiptTaxInvoice = res})
      } else {
        this.valid4 = false;
      }
    },
    async saveCreditInvoice() {
      if (this.$refs.form5.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.creditInvoice)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|CreditInvoice,${this.year}`).then((res) => {this.creditInvoice = res})
      } else {
        this.valid5 = false;
      }
    },
    async saveReceipt() {
      if (this.$refs.form6.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.receipt)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${this.year}`).then((res) => {this.receipt = res})
      } else {
        this.valid6 = false;
      }
    },
    async saveReturnCertificate() {
      if (this.$refs.form7.validate()) {
        await this.$store.dispatch('documentSeries/SetDocumentSeries', this.returnCertificate)
        await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|ReturnCertificate,${this.year}`).then((res) => {this.returnCertificate = res})
      } else {
        this.valid7 = false;
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>