<template>
  <div>
    <v-dialog
      content-class="custom-dialog-content"
      v-model="dialog"
      @click:outside="closeDialog"
      max-width="40%"
    >
      <v-card class="mx-auto" outlined>
        <v-card-text class="pa-0 text-end">
          <v-icon class="mt-2 mx-2 primary--text" @click="closeDialog"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-card-text class="text-h5 px-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            :disabled="accessRight.length < 2 && accessRight.includes('show')"
          >
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                  <v-stepper-step class="py-3 px-2 text-h6" editable step="1">
                    <v-row dense>
                      <v-col cols="6" dense>
                        <h6 class="primary--text mt-2 font-weight-regular">
                          {{ $t("common.createPdfTemplate") }}
                        </h6>
                      </v-col>
                      <v-col cols="6" class="text-end" dense>
                        <v-btn
                          color="primary"
                          small
                          :disabled="
                            !valid ||
                            (accessRight.length < 2 &&
                              accessRight.includes('show'))
                          "
                          @click="saveTemplate"
                        >
                          {{ $t("contact.save") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-step>
                  <v-stepper-content
                    step="1"
                    class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          :items="fontSizeList"
                          item-text="text"
                          item-value="value"
                          :label="$t('common.fontSize')"
                          :rules="fontSizeRequired"
                          v-model="model.font_size"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          :items="fontStyleList"
                          item-text="text"
                          item-value="value"
                          :label="$t('common.fontStyle')"
                          :rules="fontStyleRequired"
                          v-model="model.font_style"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          :items="fontWeightList"
                          item-text="text"
                          item-value="value"
                          :label="$t('common.fontWeight')"
                          :rules="fontWeightRequired"
                          v-model="model.font_weight"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          hide-details="auto"
                          v-model="model.template_name"
                          :label="$t('common.templateName')"
                          :rules="templateNameRequired"
                          required
                        />
                      </v-col>
                      <!-- <v-col cols="12" sm="6">
                        <v-select
                          :items="textDecorationList"
                          item-text="text"
                          item-value="value"
                          :label="$t('common.textDecoration')"
                          :rules="textDecorationRequired"
                          v-model="model.text_decoration"
                          required
                        />
                      </v-col> -->
                      <v-col cols="12" sm="6">
                        <v-color-picker v-model="model.text_color" hide-inputs></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Alert";

export default {
  name: "PdfTemplateForm",
  props: ["dialog", "editItemData"],
  components: {
    Alert,
  },
  data() {
    return {
      valid: false,
      e6: 1,
      alert: false,
      message: "",
      id: null,
      model: {
        font_size: "",
        font_style: "",
        font_weight: "",
        text_color: "",
        template_name: "",
        // text_decoration: "",
      },
      fontSizeList: [
        { text: "10px", value: "10px" },
        { text: "12px", value: "12px" },
        { text: "14px", value: "14px" },
        { text: "16px", value: "16px" },
      ],
      fontStyleList: [
        { text: "Italic", value: "italic" },
        { text: "Oblique", value: "oblique" },
        { text: "Normal", value: "normal" },
      ],
      fontWeightList: [
        { text: "300", value: "300" },
        { text: "400", value: "400" },
        { text: "500", value: "500" },
        { text: "600", value: "600" },
      ],
      // textDecorationList: [
      //   { text: "Overline", value: "overline" },
      //   { text: "Line-through", value: "line-through" },
      //   { text: "Underline", value: "underline" },
      //   { text: "Overline Underline", value: "overline underline" }
      // ],
    };
  },
  watch: {
    editItemData() {
      if (this.editItemData) {
        this.model.font_size = this.editItemData?.font_size
        this.model.font_style = this.editItemData?.font_style
        this.model.font_weight = this.editItemData?.font_weight
        this.model.text_color = this.editItemData?.text_color
        this.model.template_name = this.editItemData?.template_name
        // this.model.text_decoration = this.editItemData?.text_decoration
        this.id = this.editItemData?.id
      }
    },
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
    }),
    fontSizeRequired() {
      return [(v) => !!v || this.$t("formRules.fontSizeRequired")];
    },
    fontStyleRequired() {
      return [(v) => !!v || this.$t("formRules.fontStyleRequired")];
    },
    fontWeightRequired() {
      return [(v) => !!v || this.$t("formRules.fontWeightRequired")];
    },
    // textDecorationRequired() {
    //   return [(v) => !!v || this.$t("formRules.textDecorationRequired")];
    // },
    templateNameRequired() {
      return [(v) => !!v || this.$t("formRules.templateNameRequired")];
    }
  },
  methods: {
    async closeDialog() {
      this.$emit("update:dialog", false);
      this.$refs.form.reset();
      this.id = null;
    },
    async saveTemplate() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          await this.$store.dispatch('pdfTemplate/EditPdfTemplate', {
            id: this.id,
            pdfTemplate: this.model
          });
        } else {
          await this.$store.dispatch('pdfTemplate/SetPdfTemplate', this.model);
        }
        await this.$store.dispatch('pdfTemplate/GetPdfTemplate', {page: 1, limit: 10});
        this.$emit("update:dialog", false);
        this.$refs.form.reset();
      } else {
        this.valid = false;
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>
