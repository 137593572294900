<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              class="asterisk"
              v-model="model.oldPassword"
              name="oldPassword"
              :rules="oldPasswordValid"
              :label="$t('login.oldPassword')"
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPassword ? 'text' : 'password'"
              @click:append="showOldPassword = !showOldPassword"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              class="asterisk"
              v-model="model.newPassword"
              name="newPassword"
              :rules="passwordValid"
              :label="$t('login.newPassword')"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              required
            />
            <v-text-field
              class="asterisk"
              v-model="model.confirm"
              name="confirm"
              :rules="confirmValid"
              :label="$t('login.confirmPassword')"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-center">
              <v-btn
                class="text-capitalize me-3"
                color="primary"
                outlined
                small
                @click="clearData"
              >
                {{$t('settings.reset')}}
              </v-btn>
              <v-btn
                class="text-capitalize"
                color="primary"
                small
                :disabled="!valid || !accessRight.includes('edit')"
                :loading="loading"
                @click="resetPass"
              >
                {{$t('settings.save')}}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "",
  data() {
    return {
      valid: false,
      loading: false,
      model: {
        password: '',
        confirm: '',
      },
      disabledFields: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    }
  },
  computed: {
    oldPasswordValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
      ];
    },
    passwordValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
        v => (v && (v.length >= 6 && v.length <= 20)) || this.$t('formRules.passwordValidMessage'),
      ];
    },
    confirmValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
        v => (v && this.model.confirm === this.model.newPassword) || this.$t('formRules.confirmValidMessage'),
      ];
    },
    ...mapGetters({
      accessRight: 'accessRight'
    }),
  },
  methods: {
    ...mapActions({
      changePassword: "ChangePassword",
    }),
    clearData() {
      this.$refs.form.reset()
    },
    async resetPass() {
      if(this.$refs.form.validate()){
        this.loading = true;
        const params = {
          oldPassword: this.model.oldPassword,
          newPassword: this.model.newPassword,
        };
        this.changePassword(params)
        .then(() => {
          this.$refs.form.reset()
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.message);
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
