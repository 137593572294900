<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      max-width="30%"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('dbQuery.access')}}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-text-field
                ref="form"
                hide-details="auto"
                v-model="code"
                :rules="securityCodeValid"
                :label="$t('dbQuery.security')"         
                required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog">{{$t('dbQuery.cancel')}}</v-btn>
          <v-btn color="primary" :disabled="!valid" text @click="checkSecurityCode">{{$t('dbQuery.update')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AccessDbInfo",
  props: ["dialog", "tab"],
  data() {
    return {
      code: "",
      valid: false,
    }
  },
  computed: {
    securityCodeValid() {
      return [
        v => !!v || this.$t('formRules.securityCodeRequired'),
        v => !v || (v && v.length <= 13) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('dbQuery.security'), type: this.$t('common.less'), size: 13 }),
      ]
    }
  },
  methods: {
    closeDialog() {
      this.$emit("update:tab", 0);
      this.code = '';
      this.$emit("update:dialog", false);
    },
    checkSecurityCode() {
      this.$store.dispatch("accessDbInfo/AccessDbInfo", {
        code: this.code
      }).then((res) => {
        if (res.data.type === 'success') {
          this.code = '';
          this.$emit("update:dialog", false);
        }
      })
    }
  }
}
</script>