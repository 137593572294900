<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{$t("dbQuery.dbInfo")}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <v-textarea outlined :label="$t('dbQuery.query')" auto-grow v-model="query" ></v-textarea>
      </v-col>
      <!-- <v-col cols="0.5" style="flex-grow: 0.5;"/> -->
      <v-col cols="3" class="ps-2">
        <!-- <v-row>
        </v-row> -->
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <v-autocomplete v-model="selectedTable" :items="tableList" dense flat single-line return-object item-text="table">
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" @click="changeTableName(item)">
                  {{item.table}}
                </v-list-item>
            </template>
            </v-autocomplete>
            <v-btn class="me-1" width="90px" small @click="fireSelectQuery" :disabled="query.indexOf('select')!==0 || !Boolean(query)" color="primary" outlined>{{$t("dbQuery.showData")}}</v-btn>
            <v-btn small width="120px" :disabled="query.indexOf('select')===0 || !Boolean(query)" @click="fireSelectQuery" color="primary" outlined>{{$t("dbQuery.executeQuery")}}</v-btn>
          </v-col>
          <v-col class="mx-auto text-center pt-4 body-2" cols="12" v-if="computedQueryData.length">
            <span>{{ $t('common.numberOfTotalRows') }} {{computedQueryData.length}}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table :search="search" class="custom-table mb-0 ma-2 row-pointer" :headers="headers" :items="computedQueryData" :items-per-page="limit" :page.sync="page" @update:sort-by="(val) => buildUrl(val)">
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.document_details`]="props">
                {{ $t(`bookkeepingRecords.${props.item.document_details.split("-")[0].toLowerCase()}`) }}-{{ props.item.document_details.split("-")[1] }}
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{computedQueryData.length}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="Math.ceil(computedQueryData.length/limit)" :value="page"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";

export default {
  name: "",
  components: {
    ListSkeleton,
  },
  data() {
    return {
      query: "",
      selectedTable: "",
      queryData: [],
      listSkeleton: false,
      limit: 10,
      page: Number(this.$route.query.page) || 1,
      headers: [],
      pagination: { page: 1, itemsPerPage: 10 },
      search:''
    };
  },
  computed: {
    ...mapGetters({
      tableList: "dbQuery/tableList",
    }),
    params() {
      if (this.pagination) {
        return {
          ...this.pagination,
        };
      } else {
        return {};
      }
    },
    computedQueryData(){
      if(this.search)
        return this.queryData.filter(e=> Object.values(e).some(val => val && val.toString().toLowerCase().includes(this.search)));
      return this.queryData;
    }
  },
  watch: {
    params: {
      handler() {
        // this.paginate(false);
      },
      deep: true,
    },
  },
  methods: {
    changeTableName(item) {
      this.selectedTable = item.table;
      this.query = `select * from ${this.selectedTable}`;
    },
    buildUrl(val) {
      const storage = document.createElement('textarea');
      storage.value = val;
      document.body.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(storage);
    },
    async fireSelectQuery() {
      this.listSkeleton=true;
      try {
        this.queryData = await this.$store.dispatch("dbQuery/FireQuery", {
          query: this.query,
          page: this.params.page,
          limit: this.params.itemsPerPage,
          type: this.selectedTable.type
        });
        this.listSkeleton=false;
        this.headers = Object.keys(this.queryData[0]).map((val) => ({
          text: val,
          value: val,
          width: '130px'
        }));
      } catch(error) {
        this.listSkeleton = false
      }
    },
    async paginate() {
      const { page, itemsPerPage } = this.params;
      // make params for where_and
      this.queryData = await this.$store.dispatch("dbQuery/FireQuery", {
        page,
        limit: itemsPerPage,
        query: this.query,
        type: this.type
      });
      this.headers = Object.keys(this.queryData[0]).map((val) => ({
        text: val,
        value: val,
      }));
    },
  },
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>