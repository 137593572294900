<template>
  <div class="d-flex">
    <v-card
      class="mx-16 overflow-y-auto my-5 d-inline"
      width="325"
      max-height="400px"
    >
      <ListSkeleton v-if="personalMenu.length <= 0" type="list"/>
      <v-treeview
        v-else
        class="text-subtitle-1"
        dense
        selectable
        selected-color="primary"
        :items="personalMenu"
        return-object
        item-text="menu"
        item-key="slug"
        v-model="selectableMenu"
      >
      </v-treeview>
    </v-card>
    <v-card
      class="mx-16 overflow-y-auto overflow-x-hidden my-5 d-inline"
      width="325"
      max-height="400px"
    >
      <ListSkeleton v-if="listMenuChangeSkeleton" type="list"/>
      <v-list dense v-else>
        <v-list-item
          v-for="(selectMenu, index) in menuChange"
          :key="index"
          class="text-start"
        >
         {{ $t(`route.${selectMenu.slug}`) }}
        </v-list-item>
      </v-list>
    </v-card>
    <v-row class="mx-10 my-5">
      <v-col cols="auto">
        <v-btn :loading="loading" small class="me-2" :disabled="menuChange.length == 0" @click="createMenuList" color="primary">
          {{ $t("dbQuery.saveMenu") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routes } from '@/router/company.js';
import ListSkeleton from '@/components/skeleton/ListSkeleton';

export default {
  name: "PersonalMenuList",
  components: {
    ListSkeleton
  },
  data() {
    return {
      menu: [],
      loading: false,
      listMenuChangeSkeleton: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      menuList: "menuList"
    }),
    personalMenu() {
      let dataMenu = []
      for (let index = 0; index < routes.length; index++) {
        const route = routes[index];
        let data = {}
        if(route.path === '/') {
          if (route.meta.title) {
            data = Object.assign(
              {
                slug: route.meta.title.split('.')[1],
                menu: this.$t(route.meta.title),
                to: route.redirect ? route.redirect : route.path
              }
            )
            if (route.children) {
              let childData = this.makeDynamicMenu(route.children)
              if(childData.length > 0) {
                data = Object.assign({...data, children: childData})
              }
            }
          } else {
            data = Object.assign(
              {
                slug: route.children[0].meta.title.split('.')[1],
                menu: this.$t(route.children[0].meta.title),
                to: route.children[0].path
              }
            )
          }
          dataMenu.push(data)
        }
      }
      return dataMenu;
    },
    selectableMenu: {
      get() {
        return this.menuList;
      },
      set(value) {
        this.menu = value;
      },
    },
    menuChange() {
      return this.menuUpdate(this.menu);
    },
  },
  methods: {
    async createMenuList() {
      this.loading = true;
      if (this.menuList.length > 0) {
        this.menuChange.forEach(element => {
          let findMenu = this.menuList.find(item => item.slug == element.slug)
          if (findMenu) {
            element.id = findMenu.id
          }
        });
        this.$store.dispatch("EditPersonalMenu", { data: this.menuChange }).then(() => {
          this.loading = false;
          this.$store.dispatch('GetPersonalMenuList', this.user.id)
        })
      } else {
        this.$store.dispatch("AddMenuList", { data: this.menuChange }).then(() => {
          this.loading = false;
          this.$store.dispatch('GetPersonalMenuList', this.user.id)
        })
      }
    },
    menuUpdate(arr) {
      return arr?.map((x) => {
        return {
          slug: x.slug,
          userId: this.user.id,
          userName: this.user.name,
          menu: x.menu,
          to: x.to,
          id: x.id
        };
      });
    },
    makeDynamicMenu(childrens) {
      let data = []
      for (let index = 0; index < childrens.length; index++) {
        const child = childrens[index];
        if (!child.hidden) {
          let dataChild = {}
          dataChild = Object.assign(
            {
              slug: child.meta.title.split('.')[1],
              menu: this.$t(child.meta.title),
              to: child.redirect ? child.redirect : child.path
            }
          )
          if (child.children) {
            let childData = this.makeDynamicMenu(child.children)
            if (childData.length > 0) {
              dataChild = Object.assign({...dataChild, children: childData})
            }
          }
          data.push(dataChild)
        }
      }
      return data;
    }
  },
  async mounted() {
    this.listMenuChangeSkeleton = true;
    await this.$store.dispatch('GetPersonalMenuList', this.user.id)
    this.menu = this.menuList
    this.listMenuChangeSkeleton = false;
  },
};
</script>

<style scoped>
::v-deep .v-treeview-node__label {
  flex: none !important;
}
::v-deep a:-webkit-any-link {
  color: #224a7d;
  font-weight: 400;
}
</style>